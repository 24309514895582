<template>
    <div>
        <div style="margin-top:20px">{{ $t("barcode.wel").format(this.brandConfig.display) }}</div>
        <img style="width:100%" id="barcode" />
        <p style="width:100%;margin:0;text-align: center;">{{`eForm ID - ${this.custId}`}}</p>
        <!-- <p style="width:100%;margin:0;text-align: center;">{{ $t("barcode.username").format(`${this.localLastName2} ${this.localFirstName2}`) }}</p> -->
    </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import mixins from "./mixin/Registration.js";
export default {
    name: "QRcodes",
    mixins: [mixins],
    data() {
        return {
            content: "test",
            custId: "",
            brandName: "",
            // storeId: "",
            lang: "",
            brand: "",
            // country: "",
            email: "",
            // country: "",
            localFirstName2: "",
            localLastName2: "",
        };
    },
    created() {
        this.custId = this.$route.params.custId;
        this.storeId = this.$route.params.storeId;
        this.lang = this.$route.params.lang;
        this.brand = this.$route.params.brand;
        this.country = this.$route.params.countryPath;
        this.email = this.$route.params.email;
        console.log("OR",this)
        this.localFirstName2 = this.$route.params.localFirstName2;
        this.localLastName2 = this.$route.params.localLastName2;
    },
    destroyed() {
        // this.goBack();
    },
    mounted() {
        JsBarcode("#barcode", this.custId, {
            format: "CODE39",
            displayValue: false,
        });
    },
    methods: {
        goBack() {
            // this.$router.push(
            //     "/" +
            //         this.country +
            //         "/" +
            //         this.brand +
            //         "/Registration/" +
            //         this.lang +
            //         "?storeId=" +
            //         this.storeId
            // );

            this.$router.push(
                {
                    name: 'Registration',
                    params: {
                        lang: this.lang,
                        brand: this.brand,
                        country: this.country,
                        email: this.email,
                    },
                    query: {
                        storeId: this.storeId,
                    }
                }
            );
        },
    },
};
</script>

